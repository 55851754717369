import React, { Component } from "react"
import { Field } from "formik"
import { connect } from "react-redux"

import { baseURL } from "../../includes/jsFunctions"
import ErrorMsg from "../../includes/form-inputs/ErrorMsg"

class Contact extends Component {
    myTextInput = ({ name, field, placeholder, type, readonly }) => {
        const disabled = readonly ? true : false

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                        {...field}
                        readOnly={disabled}
                    />
                </div>
            </div>
        )
    }

    handleFileSelect = (evt, props, fieldName, target) => {
        var files = evt.target.files // FileList object

        const photos = Array.from(evt.target.files)

        let images = []

        document.getElementById(target).innerHTML = ""

        // Loop through the FileList and render image files as thumbnails.
        for (var i = 0, f; (f = files[i]); i++) {
            // Only process image files.
            if (!f.type.match("image.*")) {
                continue
            }

            var reader = new FileReader()

            // Closure to capture the file information.
            reader.onload = (function (theFile) {
                return function (e) {
                    // Render thumbnail.
                    var span = document.createElement("span")
                    span.innerHTML = [
                        '<img class="thumb" style="width: 200px" src="',
                        e.target.result,
                        '" title="',
                        escape(theFile.name),
                        '"/>',
                    ].join("")
                    document.getElementById(target).insertBefore(span, null)
                }
            })(f)

            images.push(f)

            // Read in the image file as a data URL.
            reader.readAsDataURL(f)
        }

        const promises = photos.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.addEventListener("load", (ev) => {
                    resolve(ev.target.result)
                })
                reader.addEventListener("error", reject)
                reader.readAsDataURL(file)
            })
        })

        props.setFieldValue(fieldName, evt.target.files[0])

        // Promise.all(promises).then(files => {

        // }, error => { console.error(error); });
    }

    render() {
        const { props } = this.props

        const { stepOne } = this.props.apply

        return (
            <div className="section-three p-8 border-t-gray-300">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-3">
                    <div className="form-input">
                        {stepOne &&
                        (stepOne.image || stepOne.common_details) ? (
                            <div className="text-center mb-2">
                                <img
                                    src={`${baseURL}/photos/${
                                        stepOne.image
                                            ? stepOne.image
                                            : stepOne.common_details
                                            ? stepOne.common_details.image
                                            : ""
                                    }`}
                                    alt="applicant-photo"
                                    style={{ width: "150px", margin: "0 auto" }}
                                />
                            </div>
                        ) : null}

                        <div className="w-full">
                            <label>Upload Photo</label>
                            <div className="text-input mt-2">
                                <input
                                    type="file"
                                    name="image"
                                    className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                    onChange={(event) =>
                                        this.handleFileSelect(
                                            event,
                                            props,
                                            "image",
                                            "selected-photo"
                                        )
                                    }
                                />
                            </div>
                            <label style={{ color: "red" }}>
                                *Maximum upload size is 150kb
                            </label>
                            <br />
                            <label style={{ color: "red" }}>
                                *Required formats are jpg, jpeg and png.
                            </label>
                            {props.touched.image && props.errors.image && (
                                <ErrorMsg msg={props.errors.image} />
                            )}
                        </div>
                        <div id="selected-photo" className="mt-3"></div>
                    </div>
                    <div className="form-input">
                        {stepOne &&
                        (stepOne.signature || stepOne.common_details) ? (
                            <div className="text-center mb-2">
                                <img
                                    src={`${baseURL}/signatures/${
                                        stepOne.signature
                                            ? stepOne.signature
                                            : stepOne.common_details
                                            ? stepOne.common_details.signature
                                            : ""
                                    }`}
                                    alt="applicant-signature"
                                    style={{ width: "150px", margin: "0 auto" }}
                                />
                            </div>
                        ) : null}

                        <div className="w-full">
                            <label>Upload Signature</label>
                            <div className="text-input mt-2">
                                <input
                                    type="file"
                                    name="signature"
                                    className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                    onChange={(event) =>
                                        this.handleFileSelect(
                                            event,
                                            props,
                                            "signature",
                                            "selected-signature"
                                        )
                                    }
                                />
                                {/* onChange={event => props.setFieldValue("signature", event.currentTarget.files[0])} */}
                            </div>
                            <label style={{ color: "red" }}>
                                *Maximum upload size is 100kb
                            </label>
                            <br />
                            <label style={{ color: "red" }}>
                                *Required formats are jpg, jpeg and png.
                            </label>
                            {props.touched.signature &&
                                props.errors.signature && (
                                    <ErrorMsg msg={props.errors.signature} />
                                )}
                        </div>
                        <div id="selected-signature" className="mt-3"></div>
                    </div>

                    <div className="form-input">
                        {stepOne &&
                        (stepOne.aadhaar_front || stepOne.common_details) ? (
                            <div className="text-center mb-2">
                                <img
                                    src={`${baseURL}/merit/docs/${
                                        stepOne.aadhaar_front
                                            ? stepOne.aadhaar_front
                                            : stepOne.common_details
                                            ? stepOne.common_details
                                                  .aadhaar_front
                                            : ""
                                    }`}
                                    alt="applicant-photo"
                                    style={{ width: "150px", margin: "0 auto" }}
                                />
                            </div>
                        ) : null}

                        <div className="w-full">
                            <label>Upload Aadhaar Front</label>
                            <div className="text-input mt-2">
                                <input
                                    type="file"
                                    name="aadhaar_front"
                                    className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                    onChange={(event) =>
                                        this.handleFileSelect(
                                            event,
                                            props,
                                            "aadhaar_front",
                                            "selected-aadhaar-front"
                                        )
                                    }
                                />
                            </div>
                            <label style={{ color: "red" }}>
                                *Maximum upload size is 200kb
                            </label>
                            <br />
                            <label style={{ color: "red" }}>
                                *Required formats are jpg, jpeg and png.
                            </label>
                            {props.touched.aadhaar_front &&
                                props.errors.aadhaar_front && (
                                    <ErrorMsg
                                        msg={props.errors.aadhaar_front}
                                    />
                                )}
                        </div>
                        <div id="selected-aadhaar-front" className="mt-3"></div>
                    </div>
                    <div className="form-input">
                        {stepOne &&
                        (stepOne.aadhaar_back || stepOne.common_details) ? (
                            <div className="text-center mb-2">
                                <img
                                    src={`${baseURL}/merit/docs/${
                                        stepOne.aadhaar_back
                                            ? stepOne.aadhaar_back
                                            : stepOne.common_details
                                            ? stepOne.common_details
                                                  .aadhaar_back
                                            : ""
                                    }`}
                                    alt="applicant-photo"
                                    style={{ width: "150px", margin: "0 auto" }}
                                />
                            </div>
                        ) : null}

                        <div className="w-full">
                            <label>Upload Aadhaar Back</label>
                            <div className="text-input mt-2">
                                <input
                                    type="file"
                                    name="aadhaar_back"
                                    className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                    onChange={(event) =>
                                        this.handleFileSelect(
                                            event,
                                            props,
                                            "aadhaar_back",
                                            "selected-aadhaar-back"
                                        )
                                    }
                                />
                                {/* onChange={event => props.setFieldValue("aadhaar_back", event.currentTarget.files[0])} */}
                            </div>
                            <label style={{ color: "red" }}>
                                *Maximum upload size is 200kb
                            </label>
                            <br />
                            <label style={{ color: "red" }}>
                                *Required formats are jpg, jpeg and png.
                            </label>
                            {props.touched.aadhaar_back &&
                                props.errors.aadhaar_back && (
                                    <ErrorMsg msg={props.errors.aadhaar_back} />
                                )}
                        </div>
                        <div id="selected-aadhaar-back" className="mt-3"></div>
                    </div>

                    <div className="form-input">
                        <Field
                            component={this.myTextInput}
                            name="email"
                            placeholder="Email"
                            type="email"
                            readonly={true}
                        ></Field>
                    </div>
                    <div className="form-input">
                        <Field
                            component={this.myTextInput}
                            name="mobile"
                            placeholder="Mobile No"
                            type="number"
                            readonly={true}
                        ></Field>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        apply: state.apply,
    }
}

export default connect(mapStateToProps)(Contact)
